<template>
  <RuesBoxSize :size="props.item?.width">
    <div v-show="loaded" class="flex flex-wrap justify-start">
      <div class="thumbSliderTop flex flex-row justify-start gap-2 w-full">
        <swiper
          :space-between="0"
          slides-per-view="auto"
          :free-mode="false"
          :watch-slides-progress="true"
          :lazy="true"
          :modules="modules"
          class="w-auto max-w-full m-0"
          @swiper="setThumbsSwiperTop"
        >
          <swiper-slide v-for="(category, categoryKey, index) in props.item?.ausstattungen" :key="categoryKey">
            <button
              :class="['test px-3 py-4 cursor-pointer border-b border-b-2 font-semibold text-sm', { 'border-b-petrol-500 text-petrol-500': activeSlide === index }, { 'border-b-neutral-100': activeSlide !== index }]"
              @click="switchSlide(index)"
            >
              {{ categoryTextMap[categoryKey] || categoryKey }}
            </button>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-show="loaded" class="relative">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="20"
        :loop="false"
        :free-mode="false"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }"
        direction="horizontal"
        :lazy="true"
        class="h-full w-full magazin-carousel"
        @swiper="setMainSwiper"
        @slide-change="handleSlideChange"
      >
        <swiper-slide v-for="(category, categoryKey) in props.item?.ausstattungen" :key="categoryKey">
          <UiHeadline type="h2" design="h2" class="mt-10 mb-5">
            {{ categoryTextMap[categoryKey] || categoryKey }}
          </UiHeadline>
          <div class="grid grid-cols-12 gap-x-5 gap-y-10 md:gap-y-16">
            <a
              v-for="(article, itemKey) in category"
              :key="itemKey"
              :href="article.href"
              target="_blank"
              :rel="article.rel"
              class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col gap-5 cursor-pointer"
            >
              <img
                v-if="article?.img_url"
                :src="article?.img_url"
                loading="lazy"
                :alt="'Bild ' + article.title"
                class="w-full h-[145px] object-cover rounded-xl"
              >
              <div
                v-if="!article?.img_url"
                class="w-full h-[145px] object-cover rounded-xl bg-neutral-100"
              />
              <div class="font-bold leading-5">
                {{ article.title }}
              </div>
            </a>
          </div>
        </swiper-slide>
      </swiper>
      <div v-show="loaded" class="flex justify-center mt-12">
        <div class="thumbSlider relative flex flex-row gap-2">
          <button aria-label="prev" class="swiper-button-prev" />
          <swiper
            :space-between="4"
            slides-per-view="auto"
            :free-mode="false"
            :watch-slides-progress="true"
            :lazy="true"
            :modules="modules"
            class="w-auto max-w-[180px]"
            @swiper="setThumbsSwiper"
          >
            <swiper-slide
              v-for="(category, categoryKey, index) in props.item?.ausstattungen"
              :key="categoryKey"
            >
              <button
                :class="['border rounded-full px-2 leading-6 text-center cursor-pointer aspect-1/1', { 'border-petrol-500 text-petrol-500': activeSlide === index }, { 'border-transparent hover:text-petrol-500': activeSlide !== index }]"
                @click="switchSlide(index)"
              >
                {{ index + 1 }}
              </button>
            </swiper-slide>
          </swiper>
          <button aria-label="next" class="swiper-button-next" />
        </div>
      </div>
    </div>
    <div v-show="!loaded" class="animate-pulse">
      <div class="col-span-12 w-full max-w-[800px] h-[50px] object-cover rounded-xl bg-neutral-100" />
      <div class="col-span-12 w-full max-w-[300px] h-[30px] object-cover rounded-xl bg-neutral-100 mb-8 mt-10" />
      <div class="grid grid-cols-12 gap-x-5 gap-y-10 md:gap-y-16 w-full overflow-hidden">
        <div
          v-for="n in 8"
          :key="n"
          class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
        >
          <div class="flex flex-col gap-5">
            <div class="w-full h-[145px] object-cover rounded-xl bg-neutral-100" />
            <div class="flex flex-col gap-2">
              <div class="w-full h-[16px] object-cover rounded-xl bg-neutral-100" />
              <div class="w-[80%] h-[16px] object-cover rounded-xl bg-neutral-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </RuesBoxSize>
</template>
<script  lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation, Thumbs, FreeMode } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'

const loaded = ref(false)
const mainSwiper = ref(null)
const thumbsSwiperTop = ref(null)
const thumbsSwiper = ref(null)
const activeSlide = ref(0)
const setThumbsSwiperTop = (swiper) => {
  thumbsSwiperTop.value = swiper
}

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper
}
const setMainSwiper = (swiper) => {
  mainSwiper.value = swiper
}
const handleSlideChange = (swiper) => {
  activeSlide.value = swiper.activeIndex
  syncThumbSwipers(swiper.activeIndex)
}

const syncThumbSwipers = (index) => {
  if (thumbsSwiper.value) {
    thumbsSwiper.value.slideTo(index)
  }
  if (thumbsSwiperTop.value) {
    thumbsSwiperTop.value.slideTo(index)
  }
}
const switchSlide = (index) => {
  if (mainSwiper.value) {
    mainSwiper.value.slideTo(index)
  }
}
const modules = [Pagination, Navigation, Thumbs, FreeMode]

if (import.meta.client) {
  onMounted(() => {
    loaded.value = true
  })
}

const props = defineProps({
  skeleton: {
    type: Boolean,
    default: true
  },
  item: {
    type: Object,
    default: null
  }
})
const t = useTranslations('RuesMagazinCarousel').translate
const categoryTextMap = {
  familienurlaub: t.value('familienurlaub'),
  aktivurlaub: t.value('aktivurlaub'),
  staedtereisen: t.value('staedtereisen'),
  strandurlaub: t.value('strandurlaub'),
  tipps: t.value('tipps'),
  kultururlaub: t.value('kultururlaub'),
  winterurlaub: t.value('winterurlaub'),
  inspiration: t.value('inspiration')
}
</script>
<style src="./scss/RuesMagazinCarousel.scss" scoped></style>
