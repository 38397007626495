<template>
  <RuesMultiCard v-if="props.item?.params.tag_art === 'drei-bild-links'" :item="props.item" :special-container="specialContainer" />
  <RuesMultiCard v-if="props.item?.params.tag_art === 'bild-links'" :item="props.item" :special-container="specialContainer" />
  <RuesMultiCard v-if="props.item?.params.tag_art === 'bild-links-paginierung'" :item="props.item" :special-container="specialContainer" />
  <RuesLinkCollection v-if="props.item?.params.tag_art === 'spalten-text-links'" :item="props.item" />
  <RuesLinkCollection v-if="props.item?.params.tag_art === 'text-links'" :item="props.item" :sidebar="sidebar" />
  <RuesMultiCardLinkAccordion v-if="props.item?.params.tag_art === 'neu'" :item="props.item" />
  <RuesMagazinCarousel v-if="props.item?.params.tag_art === 'tabbed-links'" :item="props.item" />
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  sidebar: {
    type: Boolean,
    default: false
  },
  specialContainer: {
    type: Boolean,
    default: false
  }
})
</script>

<style scoped>

</style>
