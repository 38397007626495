<template>
  <RuesBoxSize :size="props.item?.width" class="grid grid-cols-12 gap-4 usp-carousel">
    <UiHeadline type="h2" design="h2" class="col-span-12">
      {{ props.item?.title }}
    </UiHeadline>
    <div v-if="!props.skeleton && loaded" class="col-span-12 relative">
      <swiper
        :modules="modules"
        :breakpoints="{
          '768': {
            slidesPerView: 2.3
          },
          '976': {
            slidesPerView: 3
          },
        }"
        :slides-per-view="1.3"
        :space-between="18"
        :lazy="true"
        class="h-full w-full"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(usp, index) in usps"
          :key="index"
        >
          <div
            class="border border-neutral-100 rounded-xl px-5 py-5 flex flex-col gap-3 flex h-full"
          >
            <UiIcon :src="usp.icon" :width="usp.width" :height="usp.height" class="fill-petrol-500 flex align-center" />
            <div class="text-xl font-semibold">
              {{ usp.headline }}
            </div>
            <div>
              {{ usp.text }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <button v-show="isFirstSlideActive" class="swiper-button-prev flex lg:hidden" aria-label="prev" @click.stop.prevent="swiperRef.slidePrev()" />
      <button v-show="isLastSlideActive" class="swiper-button-next flex lg:hidden" aria-label="next" @click.stop.prevent="swiperRef.slideNext()" />
    </div>
    <div v-show="props.skeleton || !loaded" class="col-span-12 flex flex-nowrap gap-x-[18px] w-full overflow-hidden">
      <SkeletonUspCard design="card" class="w-[77.3%] md:w-[42%] lg:w-[33.3333%]" />
      <SkeletonUspCard design="card" class="md:w-[42%] lg:w-[33.3333%] hidden md:block" />
      <SkeletonUspCard design="card" class="lg:w-[33.3333%] hidden lg:block" />
      <SkeletonUspCard design="cardsmall" class="w-[19.7%] md:w-[10.8%] block lg:hidden" />
    </div>
  </RuesBoxSize>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import SkeletonUspCard from '~/components/page-sections/rues/skeleton/SkeletonUspCard.vue'

const props = defineProps({
  skeleton: {
    type: Boolean,
    default: true
  },
  item: {
    type: Object,
    default: null
  }
})

const loaded = ref(false)
const swiperRef = ref()

const isFirstSlideActive = computed(() => {
  return swiperRef.value?.activeIndex !== 0
})

const isLastSlideActive = computed(() => {
  return swiperRef.value?.activeIndex !== swiperRef.value?.slides.length - 1
})
const onSwiper = (swiper) => {
  swiperRef.value = swiper
}
const modules = [Navigation]
onMounted(() => {
  loaded.value = true
})

const usps = [
  {
    icon: '/svg/usp/location.svg',
    width: 42,
    height: 46,
    headline: props.item?.headline,
    text: props.item?.text
  },
  {
    icon: '/svg/usp/service.svg',
    width: 46,
    height: 46,
    headline: props.item?.headline2,
    text: props.item?.text2
  },
  {
    icon: '/svg/usp/like.svg',
    width: 48,
    height: 46,
    headline: props.item?.headline3,
    text: props.item?.text3
  }
]
</script>
<style src="./scss/RuesUSPCarousel.scss" scoped></style>
