<template>
  <div class="container 2xl:max-w-screen-xl mx-auto grid grid-cols-12 gap-x-4 gap-y-8 px-6 mb-5 mt-12 text-left">
    <div class="col-span-12">
      <UiHeadline v-if="props.headerDetails?.headline" type="h1" design="h1" :class="props.headlineCss">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="props.headerDetails?.headline" />
        <!-- eslint-enable vue/no-v-html -->
      </UiHeadline>
      <img
        v-if="props.headerDetails?.hauptbild !== '' && props.headerDetails?.hauptbild !== 'https://img.travanto.de'"
        class="mt-12 w-full rounded-xl"
        :src="props.headerDetails?.hauptbild"
        loading="eager"
        rel="preload"
        :alt="props.headerDetails?.alt || ''"
      >
      <p class="text-center text-sm mt-2">
        {{ props.headerDetails?.lizenz }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  headerDetails: {
    type: Object,
    default: null
  },
  headlineCss: {
    type: Array,
    default: null
  }
})
</script>

<style scoped>

</style>
